<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true,right:'历史反馈',feedback:true}"></v-header>
        </div>
       <div class="type">
            <span>选择反馈类型</span>
            <div class="type_box">
                <div class="item" :class="{'button_active':btn_control}" @click="report()">
                    建议反馈
                </div>
                <div class="item" :class="{'button_active':!btn_control}" @click="report()">
                    投诉举报
                </div>
            </div>
        </div>
        <div class="content">
            <span>反馈</span>
            <div class="content_box">
                <textarea placeholder="请填写反馈内容,24小时内会回复你" name="" id="" cols="30" rows="10"></textarea>
                <img src="../../assets/images/pic_add.png" alt="">
            </div>
        </div>
        <div class="contact">
            <span>联系方式</span>
            <input type="text" placeholder="请填写，您的登录手机号">
        </div>
        <div class="submit_btn">
            <button>提交</button>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            btn_control:true,
            title:this.$route.meta.title
        }
    },
    methods:{
        report(){
            this.btn_control = !this.btn_control
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #eee;
        font-size: 16px;
        .type{
            span{
                display: block;
                padding: 6px;
            }
            .type_box{
                width: 100%;
                height: 120px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                .item{
                    width: 80px;
                    height: 36px;
                    border: 1px solid ;
                    border-color: #000;
                    border-radius: 6px;
                    text-align: center;
                    line-height: 36px;
                    margin: 0 20px;
                }
            }
        }
        .content{
            display: flex;
            flex-direction: column;
            span{
                display: block;
                padding: 6px;
            }
            .content_box{
                height: 180px;
                background-color: #fff;
                padding: 10px;
                textarea{
                    border: none;
                    width: 100%;
                    height: 120px;
                }
            }
        }
        .contact{
            width: 100%;
            background-color: #eee;
            span{
                display: block;
                padding: 10px;
            }input{
                width: 100%;
                height: 32px;
                line-height: 32px;
                border: none;
                text-indent: 10px;
                margin-bottom: 40px;
            }
        }
        .submit_btn{
            position: fixed;
            bottom: 0;
            width: 100%;
            height: 36px;
            background-color: rgb(240, 66, 66);
            display: flex;
            align-items: center;
            justify-content: center;
            button{
                border: none;
                background-color: rgba(0, 0, 0, 0);
                color: #fff;
            }
        }
    }
    .button_active{
        color: rgb(14, 107, 189);
        border-color:rgb(14, 107, 189) !important;
    }
</style>